.projects
    background: var(--dark-color)
    .container
        padding: 2rem 0 0
        width: 100%
        &.full
            &:nth-child(3)
                background: red !important

.container-portfolio
    position: relative
    &.dark-svg
        background: var(--white-color)
        // .cls-1 
        //     fill: rgba($letter-color,0.04)              
        .cls-2 
            fill: rgba($letter-color,0.35)              
        .cls-3 
            fill: var(--letter-color)
    &:last-of-type
        // background: $blue-color
        background: var(--letter-color)
        .btn-secondary
            border-color: #fff
        svg
            fill: var(--white-color)
           
.portfolio__item
    max-width: 1200px
    margin: 0 auto
    min-height: 80vh
    padding: 6rem 2rem 10rem
    @include respond-to(small)
        padding: 1rem 1rem 4rem
        min-height: 50vh
    @supports (display: grid)
        display: grid
        grid-template-columns: repeat(3,minmax(100px,1fr))
        grid-template-rows: repeat(4,minmax(100px,1fr))
       
        @include respond-to(small)
            grid-template-columns: 1fr
            grid-template-rows:1fr 50px minmax(100px,200px) 100px
    
    &.reverse
        background: var(--white-color)
        color: var(--letter-color)
        .portfolio__item--img
            grid-row-start: 1
            grid-row-end: 5
            grid-column-start: 1
            grid-column-end: 3
            @include respond-to(small)
                grid-row-start: 1
                grid-row-end: 2
                grid-column-start: 1
                grid-column-end: 2
        .btn-container
            grid-column-start: 3
            grid-column-end: 4
            @include respond-to(small)
                grid-column-start: 1
                grid-column-end: 2

    &--role
        display: flex
        justify-content: center
        align-items: flex-end
        padding: 0.5rem 1rem
        font-weight: 700
        letter-spacing: 1px
        font-size: 1rem

    &--name
        text-align: center
        font-size: 2.2rem
        padding-top: 1rem
        font-weight: 700
        letter-spacing:1.4px
        line-height: 1.5
    &--desc
        text-align: center
        font-weight: 300
        line-height: 1.5
        font-style: italic
    &--links
        display: flex
        justify-content: center
        align-items: center
        max-height: 50px
        i
            margin-left:1rem
        svg
            width: 40px
            height: 40px
            fill: var(--letter-color)

    &--img
        //background: linear-gradient(to top , var(--main-color),var(--secondary-color))
        border-radius: 5px
        grid-row-start: 1
        grid-row-end: 5
        grid-column-start: 2
        grid-column-end: 4
        position: relative
        padding: 4rem
        @include respond-to(small)
                grid-row-start: 1
                grid-row-end: 2
                grid-column-start: 1
                grid-column-end: 2
                padding: 1rem
        &:after
            display: block
            content:''
            background: linear-gradient(to left , rgba(#6A02F8,1),rgba(#080013,1))
            position: absolute
            top: 4rem
            bottom: 4rem
            right: 4rem
            left: 4rem
            border-radius: 5px
            opacity: 0.2
            transition: all 0.4s e
            @include respond-to(small)
                top: 1rem
                bottom: 1rem
                right: 1rem
                left: 1rem
        &:hover
            &:after
                opacity: 0.6
        &___gif
            width: 100%
            display: block
            height: 100%
            z-index: -1
            border-radius: 5px
            box-shadow: 12px 19px 29px -15px  rgba(#6A02F8,0.3)
    .btn-container
        width: 100%
    