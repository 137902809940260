.svg__container
    overflow: hidden
    max-width: 100%
    position: absolute
    bottom: -1rem
    right:0
    left: 0
    @include respond-to(medium)
        bottom: -3rem
    @include respond-to(small)
        bottom: -5.7rem
.cls-1 
  fill: rgba(255,255,255,0.04)              
.cls-2 
    fill: rgba(255,255,255,0.35)              
.cls-3 
    fill: #fff
                    