.nav__container
    position: fixed
    top: 0
    left: 0
    right: 0
    max-width: 100vw
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    z-index: 2000
    transform: translate3d(100%,0,0)
    opacity: 0
    transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1)
    &.menu__open
        transform: translate3d(0,0,0)
        opacity: 1
.nav__list
    list-style-type: none
    padding: 1rem
    &--item
        margin-top: 0.5rem
        .nav__link
            cursor: pointer
            display: block
            text-align: center
            font-size: 1.5rem
            padding: 1rem 3rem
            text-decoration: none
            color: var(--white-color)
            background: linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%)
            background-size: 220%
            transition: all 0.4s ease-in-out
            &:hover
                background-position: 100%
                color: var(--main-color)
                transform: translateX(1rem) 