$dark-color:#080013
$blue-color: #0BBBF0
$letter-color: #6A02F8
:root
    --main-color: #625ACA
    --secondary-color: #AB5CC6
    --third-color: #0BBBF0
    --dark-color: #080013
    --white-color: #fff
    --white-low-text: #E3E3E3
    --silver-color: #666
    --letter-color: #6A02F8
    --btn-secondary: #5C56B0
    --main-font: 'Montserrat', sans-serif
*
    padding: 0
    margin: 0
    box-sizing: border-box
body
    background: var(--white-color)
    font-family: var(--main-font)
    color: var(--white-color)
#app
    overflow-x: hidden
.container:not(.full)
    max-width: 1200px
    width: 100%
    margin: 0 auto
.btn
    display: inline-block
    padding: 0.6rem 2rem
    border-radius: 1rem
    text-decoration: none
    font-weight: 700
    letter-spacing: 1px
    position: relative
    cursor: pionter
    transition: all 0.4s ease-in-out
    &:after,
    &:before
        display: inline-block
        content: ''
        height: 100%
        width: 100%
        border-radius: 1rem
        position: absolute
        top:0
        left: 0
        z-index: -1
        transition: all 0.4s ease-in-out
        background: var(--letter-color)
    &-main
        background: var(--letter-color)
        color: var(--white-color)
        border: 0.5px solid var(--white-color)
    &-secondary
        border:1px  solid transparent
        background: var(--third-color)
        &:hover
            background: var(--third-color)
            &:after,
            &:before
                background: var(--third-color)
    &:hover
        transform: translateY(-3px) scale(1.1)
        box-shadow: 0 10px 28px rgba(0, 0, 0, 0.2)
        &:after,
        &:before
            transform: scale(1.2,1.6)
            opacity: 0
.dark-text
    color: var(--dark-color)
ul 
    li 
        position: relative

@import './Components/BreakPoint.sass'
@import './Components/Header.sass'
@import './Components/SvgWves.sass'
@import './Components/NavBar.sass'
@import './Components/About.sass'
@import './Components/Projects.sass'
@import './Components/Footer.sass'
// Pages css
@import './Components/pages/aboutpage.sass'
@import './Components/pages/ContactPage.sass'


