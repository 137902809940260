@mixin respond-to($breakpoint) 
  @if $breakpoint == "small" 
    @media (max-width: 767px)
      @content
    
  

  @else if $breakpoint == "medium" 
    @media (max-width: 1200px) 
      @content

  @else if $breakpoint == "large" 
    @media (min-width: 1200px) 
      @content
    
  
