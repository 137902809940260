header
    background-repeat: no-repeat
    background-size: cover
    max-width: 100%
    width: 100vw
    padding: 2rem 1rem
    position: relative
    display: flex
    justify-content: flex-end
    align-items: center
    min-height: 95vh
    
        
.logo__container
    position: absolute
    top: 3rem
    left: 4rem
    font-weight: bold
    letter-spacing: 1px
    font-size: 1.5rem
    .dark-letters
        color: var(--dark-color)
.toggle__container
    transition: all 0.3s ease-in-out
    background: var(--main-color)
    padding: 0 1rem
    height: 4rem
    width: 4rem
    border-radius: 50%
    z-index: 2000
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.1)
    text-align: center
    cursor: pointer
    position: fixed
    top: 2rem
    right: 3rem
    z-index: 5000
    &:hover
        .menu__bar:after
            top: -0.8rem
        .menu__bar:before
            bottom: -0.8rem
    .menu__bar
        transition: all 0.3s ease-in-out
        width: 2rem
        height: 2px
        background: var(--white-color)
        display: block
        position: relative
        margin-top: 2rem
        &:after,
        &:before
            transition: all 0.3s ease-in-out
            display: inline-block
            content: ''
            width: 2rem
            height: 2px
            background: var(--white-color)
        &:after
            position: absolute
            top: -.6rem
            left: 0rem
        &:before
            position: absolute
            bottom: -.6rem
            left: 0rem
    &.menu__open
        .menu__bar
            background: transparent
            &:after
                top: 0
                transform: rotate(-0.65turn)
            &:before
                top: 0
                transform: rotate(0.65turn)

.nav__background
    background: radial-gradient(var(--main-color), var(--secondary-color))
    width: 4rem
    height: 4rem
    border-radius: 50%
    z-index: 500
    position: fixed
    top: 2rem
    right: 3rem
    transition: transform 0.4s ease-in-out, -webkit-transform 0.6s cubic-bezier(0.86, 0, 0.07, 1)
    &.menu__open
        transform: scale(80)

.title
    text-align: center
    width: 50%
    font-size: 3.5rem
    @include respond-to(small)
        font-size: 2.5rem
        width:100%
        margin: 0 0 2rem
        color: var(--dark-color)
    .letter-color
        color: var(--letter-color)
    .text-small
        font-size: 1rem
        line-height: 1.5
        font-weight: normal
        @include respond-to(small)
            display: none
.btn-container
    width: 50%
    text-align: center
    margin-top: 1.5rem
    @include respond-to(small)
        width:100%

