.contact__page
    padding-bottom: 0
    background-color:  #f1e6f5
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23625aca' fill-opacity='0.6' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")
    // background: linear-gradient(to bottom ,rgba(#625ACA,0.3),rgba(#AB5CC6,0.3)),url(https://subtlepatterns.com/patterns/sativa.png)
    &>.svg__container
        // z-index: 450
    .description
        
        color: var(--silver-color)
        font-weight: 400
        margin-bottom: 4rem
.form__container
    position: relative
    min-height: 80vh
    margin-top: 1rem
    // background: var(--main-color)
    display: flex
    justify-content: center
    align-items: center
    z-index: 400
    &--box
        margin-bottom: 10rem
        // background: var(--white-color)
    &--steps
        margin-bottom: 3rem
        overflow: hidden
        counter-reset: step
        li
            list-style: none    
            width: 33.33%
            color: var(--silver-color)
            font-size: 10px
            font-weight: 700
            // background: var(--dark-color) 
            position: relative
            float: left
            &:before
                content: counter(step)
                counter-increment: step
                width: 40px
                height: 40px
                line-height: 40px
                display: block
                font-size: 10px
                color: var(--white-color)
                background: var(--silver-color)
                border-radius: 50%
                margin: 0 auto 5px auto
                z-index: 2
            &:after
                content:  ''
                width: 100%
                height: 2px
                background: var(--silver-color)
                position: absolute
                left: -50%
                top: 20px
                z-index: -1
            &:first-child:after
                content: none
            &.active:before,
            &.active:after
                background: var(--main-color)
        

    .form
        margin-top: 2rem
        &__card
            position: relative
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            min-height: 50vh
            width: 60vw
            max-width: 800px
            padding: 2rem 1rem 5rem
            border-radius: 5px
            background: var(--main-color)
            box-shadow: 5px 10px 5px rgba(#000,0.3)
            color: var(--white-color)
            transition: all 0.5s ease-in
            
            &.active
                animation: bounce 0.5s ease-in-out 
                @keyframes bounce
                    from
                        transform:  translateX(4rem)
                        opacity: 0.6
                    to
                        transform: translateX(0)
                        opacity: 1
            .btn__container
                position: absolute
                bottom: -1rem
                display: flex
                justify-content: space-around
            .display__user--data
                width: 80%
                text-align: left
                .title-sub 
                    width: 100%
                    text-align: center
                p
                    font-weight: 500
                    margin-bottom: 0.5rem
                    padding: 1rem 0.5rem
                hr
                    color: rgba(#fff,0.5)
.contact__page
    .form__container
        .svg__container
            top: -8rem
            z-index: -1
            .cls-1 
                fill: rgba(#000000,0.5)             
            .cls-2 
                fill: rgba(#000000,0.5)               
            .cls-3 
                fill: var(--main-color)
.btn-svg
    margin-left: 1rem
    width: 60px
    height: 60px
    bottom: -1rem
    display: block
    background: var(--white-color)
    border: 5px solid var(--main-color)
    padding: 1rem
    border-radius: 50%
    &:before,&:after
        bottom: 0
        right: 0
        margin: auto
        border-radius: 50%
        width: 100%
        height: 100%

    &:hover::after, 
    &:hover::before 
        transform: scale(1.5,1.5)
    &.send
        background: var(--secondary-color)
        border-color: var(--secondary-color)
        &:before,&:after
            background: var(--secondary-color) 
        .svg-icon
            path
                fill: var(--white-color)
.svg-icon
    width: 20px
    height: 20px
    path
       fill: var(--main-color)
.input__container
    width: 100%
    max-width: 500px
    text-align: left
    display: flex
    flex-direction: column
    &--label
        margin-top: 2rem
        font-size: 1.5rem
        font-weight: 300
        color: var(--white-color)
    &--field
        font-family: var(--main-font)
        background: transparent
        border: 1px solid transparent
        border-block-end-color: var(--white-color)
        padding: 1rem 0.5rem
        color: var(--white-color)
        font-size: 1rem
        font-weight: 500
    &--list
        display: flex
        justify-content: space-between
        padding: 1rem 2rem
        font-size: 0.8rem